import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { html, render } from "lit-html";
import { TagCardList, StartScreen } from "../shared/components";
import { clearScreen } from "../shared/util";

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=dafc02c2-4230-403f-b0c0-f1d4467b4c38;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

const buttonToWriterTitle = "Writer ➜";
const buttonToWriterUrl = "https://nfcwriter.app";

async function startScanning() {
  render(
    html`<app-switcher
        title=${buttonToWriterTitle}
        url=${buttonToWriterUrl}
      ></app-switcher>
      ${StartScreen({ icon: "Tag", text: "Tap an NFC tag" })}`,
    document.body
  );
  const ndef = new NDEFReader();
  await ndef.scan();
  ndef.onreading = (event) => {
    render(
      html`<app-switcher
          title=${buttonToWriterTitle}
          url=${buttonToWriterUrl}
        ></app-switcher
        >${TagCardList({ event })}`,
      document.body
    );
  };
}

const clickHandler = {
  handleEvent() {
    startScanning();
  },
  capture: true,
};

(async () => {
  clearScreen();
  if (!("NDEFReader" in window)) {
    render(
      html`<app-switcher
          title=${buttonToWriterTitle}
          url=${buttonToWriterUrl}
        ></app-switcher>
        ${StartScreen({
          icon: "Sad",
          text: "Your device does not support NFC",
        })}`,
      document.body
    );
    return null;
  }
  const permissionName = "nfc" as PermissionName;
  const nfcPermissionStatus = await navigator.permissions.query({
    name: permissionName,
  });
  if (nfcPermissionStatus.state === "granted") {
    // NFC access was previously granted, so we can start NFC scanning now.
    startScanning();
  } else {
    render(
      html` <app-switcher
          title=${buttonToWriterTitle}
          url=${buttonToWriterUrl}
        ></app-switcher
        ><span @click=${clickHandler}>
          ${StartScreen({
            icon: "Finger",
            text: "Tap the screen to start scanning",
          })}
        </span>`,
      document.body
    );
  }
  return null;
})();
