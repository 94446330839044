const clearScreen = () => {
  const body = document.querySelector("body") as HTMLBodyElement;
  body.innerHTML = "";
};

const readTextBasedRecord = (record: NDEFRecord) => {
  const encoding = record.encoding || "utf-8";
  const textDecoder = new TextDecoder(encoding);
  return textDecoder.decode(record.data);
};

const toValidURL = (link: string) =>
  link.indexOf("://") === -1 && link.indexOf("mailto:") === -1
    ? `https://${link}`
    : link;

export { clearScreen, readTextBasedRecord, toValidURL };
