const fingerSVGPath =
  "M6.15 18.8q-.2-.1-.2-.325t.1-.425q3.1-4.3 7.85-6.65 4.75-2.35 10.15-2.35t10.175 2.3q4.775 2.3 7.925 6.6.15.25.125.4-.025.15-.175.3t-.375.15q-.225 0-.425-.25-2.95-4.1-7.525-6.3t-9.725-2.2q-5.15 0-9.65 2.225T6.9 18.55q-.2.25-.375.3-.175.05-.375-.05ZM30 43.95q-5.15-1.3-8.475-5.15-3.325-3.85-3.325-9.35 0-2.35 1.725-3.95t4.125-1.6q2.4 0 4.125 1.6t1.725 3.95q0 1.9 1.475 3.2 1.475 1.3 3.425 1.3 1.9 0 3.325-1.3 1.425-1.3 1.425-3.2 0-6.15-4.575-10.3T24.05 15q-6.35 0-10.925 4.15Q8.55 23.3 8.55 29.45q0 1.2.275 3.125T10 36.95q.1.25 0 .375t-.25.225q-.2.1-.425.05-.225-.05-.325-.3-.65-1.9-1.025-3.875Q7.6 31.45 7.6 29.45q0-6.45 4.9-11.025t11.55-4.575q6.8 0 11.675 4.5 4.875 4.5 4.875 11.1 0 2.3-1.7 3.9t-4.1 1.6q-2.45 0-4.2-1.6t-1.75-3.9q0-1.95-1.425-3.25t-3.375-1.3q-1.95 0-3.4 1.3t-1.45 3.25q0 5.2 3.15 8.675Q25.5 41.6 30.2 43q.3.1.375.25.075.15.025.35-.05.25-.2.35-.15.1-.4 0Zm-17.65-36q-.25.1-.375.025-.125-.075-.225-.225-.1-.1-.1-.3 0-.2.15-.3 2.85-1.55 5.975-2.35Q20.9 4 24.05 4q3.25 0 6.375.8T36.4 7.05q.25.1.275.3.025.2-.075.35-.1.15-.275.25-.175.1-.425 0-2.75-1.35-5.75-2.125-3-.775-6.1-.775-3.1 0-6.05.725-2.95.725-5.65 2.175Zm6.7 35.45q-2.9-3-4.525-6.3-1.625-3.3-1.625-7.65 0-4.45 3.275-7.5t7.875-3.05q4.6 0 7.925 3.05t3.325 7.5q0 .25-.125.375t-.375.125q-.25 0-.4-.125-.15-.125-.15-.375 0-4.05-3.025-6.8T24.05 19.9q-4.15 0-7.125 2.75-2.975 2.75-2.975 6.8 0 4.25 1.475 7.25t4.375 6q.2.2.175.375-.025.175-.175.325-.1.1-.325.175-.225.075-.425-.175Zm15.3-3.65q-4.4 0-7.625-2.925Q23.5 33.9 23.5 29.45q0-.25.125-.4.125-.15.375-.15t.375.15q.125.15.125.4 0 4.05 2.975 6.675t6.875 2.625q.4 0 .95-.05.55-.05 1.2-.15.25-.05.4.075.15.125.2.275.05.2-.025.35-.075.15-.325.2-.9.25-1.575.275-.675.025-.825.025Z";
const tagSVGPath =
  "M12 36h24V12H25.5q-1.3 0-2.15.85-.85.85-.85 2.15v6.4q-.75.45-1.125 1.1Q21 23.15 21 24q0 1.3.85 2.15.85.85 2.15.85 1.3 0 2.15-.85Q27 25.3 27 24q0-.85-.4-1.5t-1.1-1.1V15H33v18H15V15h5.65v-3H12Zm-3 6q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z";
const sadSVGPath =
  "M31.3 21.35q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm-14.6 0q1.15 0 1.925-.8.775-.8.775-1.9 0-1.15-.775-1.925-.775-.775-1.925-.775-1.1 0-1.9.775-.8.775-.8 1.925 0 1.1.8 1.9.8.8 1.9.8Zm7.3 5.8q-3.35 0-6.075 1.875T13.9 34h2.65q1.1-2.1 3.1-3.25t4.4-1.15q2.35 0 4.325 1.175T31.5 34h2.6q-1.25-3.15-4-5T24 27.15ZM24 44q-4.15 0-7.8-1.575-3.65-1.575-6.35-4.275-2.7-2.7-4.275-6.35Q4 28.15 4 24t1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4t7.8 1.575q3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24t-1.575 7.8q-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.275Q28.15 44 24 44Zm0-20Zm0 17q7.1 0 12.05-4.95Q41 31.1 41 24q0-7.1-4.95-12.05Q31.1 7 24 7q-7.1 0-12.05 4.95Q7 16.9 7 24q0 7.1 4.95 12.05Q16.9 41 24 41Z";
const serialSVGPath =
  "M11 30v-9.6H8V18h5.4v12Zm6.6 0v-5.5q0-.75.475-1.225.475-.475 1.225-.475h4.9v-2.4h-6.6V18h7.3q.75 0 1.225.475.475.475.475 1.225v3.8q0 .75-.475 1.225-.475.475-1.225.475H20v2.4h6.6V30ZM30 30v-2.4h6.6v-2.4H32v-2.4h4.6v-2.4H30V18h7.3q.75 0 1.225.475Q39 18.95 39 19.7v8.6q0 .75-.475 1.225Q38.05 30 37.3 30Z";
const recordTypeSVGPath =
  "M15.95 35.5h16.1v-3h-16.1Zm0-8.5h16.1v-3h-16.1ZM11 44q-1.2 0-2.1-.9Q8 42.2 8 41V7q0-1.2.9-2.1Q9.8 4 11 4h18.05L40 14.95V41q0 1.2-.9 2.1-.9.9-2.1.9Zm16.55-27.7V7H11v34h26V16.3ZM11 7v9.3V7v34V7Z";
const mediaTypeSVGPath =
  "M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z";
const idSVGPath =
  "m12.5 40 2.15-8.5H7l.75-3h7.65l2.25-9H9l.75-3h8.65L20.5 8h2.95l-2.1 8.5h9.05L32.5 8h2.95l-2.1 8.5H41l-.75 3H32.6l-2.25 9H39l-.75 3H29.6L27.5 40h-3l2.15-8.5H17.6L15.5 40Zm5.85-11.5h9.05l2.25-9H20.6Z";
const encodingSVGPath =
  "M28 19.3v-2.4q1.65-.7 3.375-1.05Q33.1 15.5 35 15.5q1.3 0 2.55.2 1.25.2 2.45.5v2.2q-1.2-.45-2.425-.675Q36.35 17.5 35 17.5q-1.9 0-3.65.475T28 19.3Zm0 11v-2.45q1.65-.7 3.375-1.025Q33.1 26.5 35 26.5q1.3 0 2.55.2 1.25.2 2.45.5v2.2q-1.2-.45-2.425-.675Q36.35 28.5 35 28.5q-1.9 0-3.65.45T28 30.3Zm0-5.5v-2.4q1.65-.7 3.375-1.05Q33.1 21 35 21q1.3 0 2.55.2 1.25.2 2.45.5v2.2q-1.2-.45-2.425-.675Q36.35 23 35 23q-1.9 0-3.65.475T28 24.8ZM12.4 33q2.7 0 5.225.625 2.525.625 4.975 1.875V14.15q-2.25-1.5-4.875-2.325Q15.1 11 12.4 11q-1.9 0-3.725.475Q6.85 11.95 5 12.65v21.7q1.55-.7 3.525-1.025Q10.5 33 12.4 33Zm13.2 2.5q2.5-1.25 4.9-1.875Q32.9 33 35.6 33q1.9 0 3.925.3t3.475.8V12.65q-1.7-.85-3.6-1.25-1.9-.4-3.8-.4-2.7 0-5.225.825-2.525.825-4.775 2.325ZM24.1 40q-2.55-1.9-5.55-2.925T12.4 36.05q-1.85 0-3.6.45t-3.5 1.1q-1.15.55-2.225-.15Q2 36.75 2 35.45V12.3q0-.75.35-1.375T3.4 9.95q2.1-1 4.375-1.475Q10.05 8 12.4 8q3.15 0 6.125.85t5.575 2.6q2.55-1.75 5.475-2.6Q32.5 8 35.6 8q2.35 0 4.6.475 2.25.475 4.35 1.475.7.35 1.075.975T46 12.3v23.15q0 1.4-1.125 2.125-1.125.725-2.225.025-1.7-.7-3.45-1.125-1.75-.425-3.6-.425-3.15 0-6.05 1.05T24.1 40ZM13.8 23.55Z";
const languageSVGPath =
  "M24 44q-4.2 0-7.85-1.575Q12.5 40.85 9.8 38.15q-2.7-2.7-4.25-6.375Q4 28.1 4 23.9t1.55-7.825Q7.1 12.45 9.8 9.75t6.35-4.225Q19.8 4 24 4q4.2 0 7.85 1.525Q35.5 7.05 38.2 9.75q2.7 2.7 4.25 6.325Q44 19.7 44 23.9t-1.55 7.875Q40.9 35.45 38.2 38.15t-6.35 4.275Q28.2 44 24 44Zm0-2.9q1.75-1.8 2.925-4.125Q28.1 34.65 28.85 31.45H19.2q.7 3 1.875 5.4Q22.25 39.25 24 41.1Zm-4.25-.6q-1.25-1.9-2.15-4.1-.9-2.2-1.5-4.95H8.6Q10.5 35 13 37.025q2.5 2.025 6.75 3.475Zm8.55-.05q3.6-1.15 6.475-3.45 2.875-2.3 4.625-5.55h-7.45q-.65 2.7-1.525 4.9-.875 2.2-2.125 4.1Zm-20.7-12h7.95q-.15-1.35-.175-2.425-.025-1.075-.025-2.125 0-1.25.05-2.225.05-.975.2-2.175h-8q-.35 1.2-.475 2.15T7 23.9q0 1.3.125 2.325.125 1.025.475 2.225Zm11.05 0H29.4q.2-1.55.25-2.525.05-.975.05-2.025 0-1-.05-1.925T29.4 19.5H18.65q-.2 1.55-.25 2.475-.05.925-.05 1.925 0 1.05.05 2.025.05.975.25 2.525Zm13.75 0h8q.35-1.2.475-2.225Q41 25.2 41 23.9q0-1.3-.125-2.25T40.4 19.5h-7.95q.15 1.75.2 2.675.05.925.05 1.725 0 1.1-.075 2.075-.075.975-.225 2.475Zm-.5-11.95h7.5q-1.65-3.45-4.525-5.75Q32 8.45 28.25 7.5q1.25 1.85 2.125 4t1.525 5Zm-12.7 0h9.7q-.55-2.65-1.85-5.125T24 7q-1.6 1.35-2.7 3.55-1.1 2.2-2.1 5.95Zm-10.6 0h7.55q.55-2.7 1.4-4.825.85-2.125 2.15-4.125-3.75.95-6.55 3.2T8.6 16.5Z";
const textSVGPath = "M21.5 40V13H10V8h28v5H26.5v27Z";
const urlSVGPath =
  "M22.5 34H14q-4.15 0-7.075-2.925T4 24q0-4.15 2.925-7.075T14 14h8.5v3H14q-2.9 0-4.95 2.05Q7 21.1 7 24q0 2.9 2.05 4.95Q11.1 31 14 31h8.5Zm-6.25-8.5v-3h15.5v3ZM25.5 34v-3H34q2.9 0 4.95-2.05Q41 26.9 41 24q0-2.9-2.05-4.95Q36.9 17 34 17h-8.5v-3H34q4.15 0 7.075 2.925T44 24q0 4.15-2.925 7.075T34 34Z";
const jsonSVGPath = "M18.45 36v-3.05H42V36Zm0-10.5v-3H42v3ZM6 15v-3h36v3Z";
const unknownSVGPath =
  "M11 44q-1.2 0-2.1-.9Q8 42.2 8 41V7q0-1.2.9-2.1Q9.8 4 11 4h18.05L40 14.95V41q0 1.2-.9 2.1-.9.9-2.1.9Zm16.55-27.7V7H11v34h26V16.3ZM11 7v9.3V7v34V7Z";

const Icons = {
  SerialNumber: serialSVGPath,
  RecordType: recordTypeSVGPath,
  MediaType: mediaTypeSVGPath,
  ID: idSVGPath,
  Encoding: encodingSVGPath,
  Language: languageSVGPath,
  Text: textSVGPath,
  URL: urlSVGPath,
  AbsoluteURL: urlSVGPath,
  JSON: jsonSVGPath,
  Unknown: unknownSVGPath,
  Finger: fingerSVGPath,
  Tag: tagSVGPath,
  Sad: sadSVGPath,
};

export default Icons;
