/* eslint-disable max-classes-per-file */
import { css, LitElement } from "lit";
import { html, TemplateResult } from "lit-html";

import { customElement, property, query } from "lit/decorators";
import Icons from "./icons";
import { readTextBasedRecord, toValidURL } from "./util";

interface IStartScreen {
  icon: keyof typeof Icons;
  text: string;
}

const StartScreen = ({ icon, text }: IStartScreen) => html`
  <div class="funcScreen">
    <svg id="icon" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <path
        id="svgPath"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="${Icons[icon]}"
      ></path>
    </svg>
    <p id="helperText">${text}</p>
  </div>
`;

interface IInfoCard {
  title: string;
  content?: string | TemplateResult;
  icon: keyof typeof Icons;
}

// interface IEditableCard {
//   title: string;
//   icon: keyof typeof Icons;
//   onChange: (event: InputEvent) => void;
// }

// @customElement("test-element")
// export class TestElement extends LitElement {
//   static render() {
//     return html`<p>testelement</p>`;
//   }
// }

const globalStyle = css`
  body {
    background: linear-gradient(45deg, #d42222, #22d46c, #2277d2);
    background-size: 600% 600%;
    animation: GradientBackground 10s ease infinite;
    height: 100%;
    margin: 0;
  }

  p,
  h4,
  h3,
  h2,
  h1 {
    font-family: "Montserrat", sans-serif;
  }

  h4 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  @keyframes GradientBackground {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  .funcScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    margin-top: 40%;
  }

  .funcScreen p {
    font-size: x-large;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
  }

  @media screen and (min-width: 749px) {
    .funcScreen {
      margin-top: 0;
      height: 100vh;
    }
    .funcScreen p {
      font-size: xxx-large;
    }
  }

  .funcScreen svg {
    display: block;
  }

  .card {
    /* Add shadows to create the "card" effect */
    display: flex;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    background-color: ghostwhite;
    height: 10%;
    margin: 2%;
    animation-duration: 1.5s;
    animation-name: slidein;
    min-height: 66px;
  }

  .card #icon {
    width: 10%;
    padding: 16px;
  }

  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .card h4 {
    margin-bottom: 4px;
  }

  .card p {
    margin-top: 4px;
  }

  /* Add some padding inside the card container */
  .container {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 16px;
    padding-left: 8px;
    width: 90%;
  }

  @keyframes slidein {
    from {
      margin-top: 100%;
    }
  }

  input[type="text"],
  input[type="url"] {
    border: none;
    border-bottom: 2px solid;
    background: none;
    margin-bottom: 4px;
    font-family: "Montserrat", sans-serif;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: large;
  }
`;

@customElement("snack-bar")
export class SnackBar extends LitElement {
  @property()
  message: string = "";

  static styles = [
    css`
      /* The snackbar - position it at the bottom and in the middle of the screen */
      #snackbar {
        min-width: 250px; /* Set a default minimum width */
        margin-left: -125px; /* Divide value of min-width by 2 */
        background-color: #333; /* Black background color */
        color: #fff; /* White text color */
        text-align: center; /* Centered text */
        border-radius: 2px; /* Rounded borders */
        padding: 16px; /* Padding */
        position: fixed; /* Sit on top of the screen */
        z-index: 1; /* Add a z-index if needed */
        left: 50%; /* Center the snackbar */
        bottom: 30px; /* 30px from the bottom */
      }

      /* Show the snackbar when clicking on a button (class added with JavaScript) */
      #snackbar {
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
        However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
      }

      /* Animations to fade the snackbar in and out */
      @-webkit-keyframes fadein {
        from {
          bottom: 0;
          opacity: 0;
        }
        to {
          bottom: 30px;
          opacity: 1;
        }
      }

      @keyframes fadein {
        from {
          bottom: 0;
          opacity: 0;
        }
        to {
          bottom: 30px;
          opacity: 1;
        }
      }

      @-webkit-keyframes fadeout {
        from {
          bottom: 30px;
          opacity: 1;
        }
        to {
          bottom: 0;
          opacity: 0;
        }
      }

      @keyframes fadeout {
        from {
          bottom: 30px;
          opacity: 1;
        }
        to {
          bottom: 0;
          opacity: 0;
        }
      }
    `,
    globalStyle,
  ];

  render() {
    return this.message && html` <div id="snackbar">${this.message}</div> `;
  }

  updated() {
    this.hidden = false;
    setTimeout(() => {
      this.hidden = true;
    }, 3000);
  }
}

@customElement("card-button")
export class CardButton extends LitElement {
  @property()
  title: string = "";

  // static styles = {css`

  // `, ...globalStyle};

  static styles = [
    css`
      .buttoncontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: auto;
        width: 100%;
      }
      .buttoncontainer {
        background: linear-gradient(135deg, #2277d2, #22d46c, #d42222);
        background-size: 600% 600%;
        animation: GradientBackground 10s ease infinite;
      }
    `,
    globalStyle,
  ];

  render() {
    return html`
      <div class="card">
        <div class="buttoncontainer"><h3>${this.title}</h3></div>
      </div>
    `;
  }
}

@customElement("editable-card")
export class EditableCard extends LitElement {
  @property()
  title: string = "";

  @property()
  icon: keyof typeof Icons = "ID";

  @property()
  key: string = "";

  // @property({ reflect: true })
  // input: string = "";

  @query("input", true) input!: HTMLInputElement;

  static styles = globalStyle;

  onChange(e: Event) {
    console.log(this.input.value);
    const inputVal =
      this.key === "url" ? toValidURL(this.input.value) : this.input.value;
      this.dispatchEvent(
        new CustomEvent<{ [key: string]: string }>("input-changed", {
          detail: { [this.key]: inputVal },
        })
      );
  }

  render() {
    return html`
      <div class="card">
        <svg id="icon" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <path d="${Icons[this.icon]}"></path>
        </svg>
        <div class="container">
          <input
            type=${this.key === "url" ? "url" : "text"}
            placeholder=${this.title}
            @input=${this.onChange}
            @change=${this.onChange}
          />
        </div>
      </div>
    `;
  }
}
// const EditableCard = ({ title, icon, onChange }: IEditableCard) => html`
//   <div class="card">
//     <svg id="icon" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
//       <path d="${Icons[icon]}"></path>
//     </svg>
//     <div class="container">
//       <h4>${title}</h4>
//       <input type="text" @change=${onChange} />
//     </div>
//   </div>
// `;
@customElement("editable-card-list")
export class EditableCardList extends LitElement {
  //   onChange,
  //   onClick,
  // }: {
  //   onChange: (data: { key: string; val: string }) => void;
  //   onClick: (event: MouseEvent) => void;
  // } => {
  @property()
  // data: Record<string, string>[] = []
  data: { [key: string]: string } = {};

  @property()
  alertMessage: string = "";

  @property()
  ndef = new NDEFReader();

  constructor() {
    super();
    this.ndef.scan();
  }

  updateData(newData: { detail: { [key: string]: string } }) {
    this.data = { ...this.data, ...newData.detail };
  }

  async writeData() {
    const records = Object.keys(this.data).map((key) => ({
      recordType: key,
      data: this.data[key],
    }));
    console.log(records);
    try {
      this.alertMessage = "Writing...";
      await this.ndef.write({ records });
      this.alertMessage = "Write successful";
    } catch (e: unknown) {
      if (e instanceof Error) {
        this.alertMessage = `Failed to write: ${e}`;
      } else {
        this.alertMessage = "Failed to write";
      }
    }
  }

  static styles = globalStyle;

  // constructor() {
  //   super();
  //   this.data = {};
  // }
  // data: { key: string; val: string }

  // const onChangeCard = (event: InputEvent) => {
  //   onChange();
  // };
  render() {
    return html`
      <editable-card
        title="Text"
        icon="Text"
        key="text"
        @input-changed=${this.updateData}
      ></editable-card>
      <editable-card
        title="Url"
        icon="URL"
        key="url"
        @input-changed=${this.updateData}
      ></editable-card>
      <card-button title="Write" @click=${this.writeData}></card-button>
      <snack-bar message=${this.alertMessage}></snack-bar>
    `;
  }
}

@customElement("app-switcher")
export class AppSwitcher extends LitElement {
  @property()
  title: string = "";

  @property()
  url: string = "";

  constructor() {
    super();
    // eslint-disable-next-line no-return-assign
    this.addEventListener("click", () => (window.location.href = this.url));
  }

  static styles = [
    css`
      .appswitcher {
        /* Add shadows to create the "card" effect */
        display: flex;
        flex-direction: row;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 50px; /* 5px rounded corners */
        background-color: ghostwhite;
        height: 5%;
        margin: 2%;
        justify-content: center;
        width: 30%;
      }
      .appswitcher-container {
        display: flex;
        justify-content: flex-end;
      }
      p {
        margin: 0.7em;
      }
    `,
    globalStyle,
  ];

  render() {
    return html`
      <div class="appswitcher-container">
        <div class="appswitcher">
          <p>${this.title}</p>
        </div>
      </div>
    `;
  }
}

const InfoCard = ({ title, content, icon }: IInfoCard) => html`
  ${content
    ? html`
        <div class="card">
          <svg id="icon" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="${Icons[icon]}"></path>
          </svg>
          <div class="container">
            <h4>${title}</h4>
            <p>${content}</p>
          </div>
        </div>
      `
    : ""}
`;

const MimeCard = ({ record }: { record: NDEFRecord }) => {
  const textDecoder = new TextDecoder();
  switch (record.mediaType) {
    case "application/json":
      return InfoCard({
        title: "JSON",
        content: JSON.stringify(
          JSON.parse(textDecoder.decode(record.data)),
          null,
          2
        ),
        icon: "JSON",
      });
    default:
      return html``;
  }
};
const PayloadCard = ({ record }: { record: NDEFRecord }) => {
  switch (record.recordType) {
    case "text":
      return InfoCard({
        title: "Text",
        content: readTextBasedRecord(record),
        icon: "Text",
      });
    case "url": {
      const url = readTextBasedRecord(record);
      return InfoCard({
        title: "URL",
        content: html`<a href="${url}" target="_blank">${url}</a>`,
        icon: "URL",
      });
    }
    case "absolute-url": {
      const url = readTextBasedRecord(record);
      return InfoCard({
        title: "URL",
        content: html`<a href="${url}" target="_blank">${url}</a>`,
        icon: "URL",
      });
    }
    case "mime":
      return MimeCard({ record });
    default:
      return html``;
  }
};

const RecordPropsCardList = ({ record }: { record: NDEFRecord }) => html`
  ${InfoCard({
    title: "Record Type",
    content: record.recordType,
    icon: "RecordType",
  })}
  ${InfoCard({
    title: "Media Type",
    content: record.mediaType,
    icon: "MediaType",
  })}
  ${InfoCard({ title: "ID", content: record.id, icon: "ID" })}
  ${InfoCard({ title: "Encoding", content: record.encoding, icon: "Encoding" })}
  ${InfoCard({ title: "Language", content: record.lang, icon: "Language" })}
  ${PayloadCard({ record })};
`;

const TagCardList = ({ event }: { event: NDEFReadingEvent }) => html`
  ${InfoCard({
    title: "Serial Number",
    content: event.serialNumber,
    icon: "SerialNumber",
  })}
  ${event.message.records.map((record) => RecordPropsCardList({ record }))}
`;

export { TagCardList, StartScreen };
